/* Colors */
:root {
    --primary-navy:      #0a2156;
    --primary-gold:      #e0a614;
    --primary-tangerine: #e26713;
    --secondary-blue:    #1d6a91;
    --secondary-aqua:    #0bAfaa;
    --secondary-cherry:  #a30707;
    --neutral-dark:      #222222;
    --neutral-slate:     #444444;
    --neutral-mist:      #aaaaaa;
    --neutral-white:     #ffffff;
    --gradient-sunrise:   (var(--primary-tangerine), var(--primary-gold));
    --gradient-sunrise-0deg:   (  0deg, var(--primary-tangerine), var(--primary-gold));
    --gradient-sunrise-45deg:  ( 45deg, var(--primary-tangerine), var(--primary-gold));
    --gradient-sunrise-90deg:  ( 90deg, var(--primary-tangerine), var(--primary-gold));
    --gradient-sunrise-135deg: (135deg, var(--primary-tangerine), var(--primary-gold));
    --gradient-sunrise-180deg: (180deg, var(--primary-tangerine), var(--primary-gold));
    --gradient-sunrise-225deg: (225deg, var(--primary-tangerine), var(--primary-gold));
    --gradient-sunrise-270deg: (270deg, var(--primary-tangerine), var(--primary-gold));
    --gradient-sunrise-315deg: (315deg, var(--primary-tangerine), var(--primary-gold));
    --gradient-sunrise-360deg: (360deg, var(--primary-tangerine), var(--primary-gold));
    --gradient-aqua:      (var(--secondary-aqua), var(--secondary-blue), var(--primary-navy));
    --gradient-aqua-0deg:      (  0deg, var(--secondary-aqua), var(--secondary-blue), var(--primary-navy));
    --gradient-aqua-45deg:     ( 45deg, var(--secondary-aqua), var(--secondary-blue), var(--primary-navy));
    --gradient-aqua-90deg:     ( 90deg, var(--secondary-aqua), var(--secondary-blue), var(--primary-navy));
    --gradient-aqua-135deg:    (135deg, var(--secondary-aqua), var(--secondary-blue), var(--primary-navy));
    --gradient-aqua-180deg:    (180deg, var(--secondary-aqua), var(--secondary-blue), var(--primary-navy));
    --gradient-aqua-225deg:    (225deg, var(--secondary-aqua), var(--secondary-blue), var(--primary-navy));
    --gradient-aqua-270deg:    (270deg, var(--secondary-aqua), var(--secondary-blue), var(--primary-navy));
    --gradient-aqua-315deg:    (315deg, var(--secondary-aqua), var(--secondary-blue), var(--primary-navy));
    --gradient-aqua-360deg:    (360deg, var(--secondary-aqua), var(--secondary-blue), var(--primary-navy));
}