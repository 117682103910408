#root {
  background: linear-gradient(180deg, #0A2156, #1d6a91,  #0bafaa);
  min-height:100vh;
  scroll-behavior: smooth !important;
}

.swal2-container {
  z-index: 20000 !important;
}

.swal-container {
  z-index: 20000;
}

.pointer {
  cursor: pointer;
  display: flex;
  align-items:center;
}

.death-input {
  border-style: hidden hidden solid;
  border-color: #66cc66;
  width: 100%;
  z-index: 1032;
}

.form-input {
  margin-bottom: 30px;
}

.flex {
  display: flex;
  flex-wrap: nowrap;
}

.d-block {
  display: block !important;
}

a {
  text-decoration: underline;
  color:#10c1b8;
  &:hover {
    color:#0bafaa;
  }
}

/*Disable all animations if user has specified they prefer reduced motion*/
// @media (prefers-reduced-motion: reduce) {
//   * {
//     animation-play-state: paused !important;
//     transition: none !important;
//     scroll-behavior: auto !important;
//   }
// }