#navigation {
  z-index: 998;
  width: calc(100vw - 20px);
  margin: 0;
  padding: 7.5px 10px;
  // background: linear-gradient(0deg, #0a2156, #1d6a91, #0bafaa);
  top: 0;
  overflow-y: scroll;
  overflow-x: scroll;
  -webkit-box-shadow: 2px 3px 4px 1px rgba(0, 0, 0, 0.68);
  box-shadow: 2px 3px 14px 1px rgba(0, 0, 0, 0.68);

  @media (min-width: 1023px) {
    position: fixed;
    height: 100vh;
    width: 230px;
    padding: 20px;
   
 
  }
  @media (max-width: 1023px) {
    // background: linear-gradient(90deg, #0A2156, #1d6a91,  #0bafaa)
    background:  none;
  }
 
  .logo-container {
    display: flex;
    flex: 1 0 0%;
    align-items: center;
    justify-content: center;
  }

  .menu-container {
    display: flex;
    flex: 1 0 33%;
    align-items: center;
    justify-content: flex-start;
    color: white;

    @media (min-width: 1023px) {
      display: none;
    }
  }

  .logo-container {
    display: flex;
    flex: 1 0 33%;
    align-items: center;
    justify-content: flex;
    color: white;
    max-width: 3rem;
    @media (min-width: 1023px) {
      display: none;
    }
  }
  
  .button-container {
    display: flex;
    flex: 1 0 33%;
    align-items: center;
    justify-content: flex-end;
    color: white;

    button {
      margin-right: 0.2rem;
      svg {
        transform: scale(1.6) translate(-2.5px, 1px);
      }
    }

    // @media (max-width: 285px) {
    //   button {display: none;
    //   }
    // }
    @media (min-width: 1023px) {
      display: none;
    }
  }

  #iternalLogo {
    max-width: 100%;
    max-height: 36px;
  }

  .navigation-link-container {
    display: none;
    padding: 1rem;
    max-width: 200px;
    

    @media (min-width: 1023px) {
      display: block;
      padding: 1rem 0 0 0;
    }

    .navigation-link {
      font-family: 'Open Sans', Helvetica, Arial, sans-serif;
      display: flex;
      align-items: center;
      text-transform: uppercase;
      padding: 10px 0 10px 0;
      color: white;
      font-size: 18px;
      cursor: pointer;
      .dropdown {
        svg {
          width:0.5em;
        }
      }
      
      svg {
        margin-right:10px;
      }

      a {
        text-decoration: none;
        color: white;
      }

      &.navigation-link-indent {
        padding-left: 10px;

        @media (min-width: 1023px) {
          font-size: 16px;
        }
      }

      &.navigation-notification {
        @media (max-width: 1023px) {
          display: none;
        }
      }
    }

    .is-open {
      .dropdown {
        svg {
          -webkit-transform: rotate(90deg);
          -ms-transform: rotate(90deg);
           transform: rotate(90deg);
        }
      }
    }
    .logout {
      @media (min-width: 1023px) and (max-height: 699px) {
        padding-bottom: 2rem;
      }
    }
  }

  #navigation-logout {
    display: none;
    padding: 0 15px 15px;
    align-items: center;
    text-transform: uppercase;
    color: white;
    font-size: 18px;
    cursor: pointer;

    .navigation-link {
      padding: 20px 0 0 0;

      svg {
        margin-right:10px;
      }
    }

    @media (min-width: 1023px) {
      display: block;
      padding: 15px 0 0 0;
      position: fixed;
      bottom: 30px;
    }

    a {
      text-decoration: none;
      color: white;
    }
  }
}